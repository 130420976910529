/** @jsx jsx */
import { jsx } from 'theme-ui';

import MainLayout from './MainLayout';
import { Container } from '../components/Container';
import { useTranslation } from '../i18n/TranslationContext';

export const EmptyLayout: React.FC = ({ children }) => {
  const t = useTranslation();
  return (
    <MainLayout>
      <Container id="content">{children}</Container>
    </MainLayout>
  );
};
