/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex } from 'rebass';
import { H2, H3, P } from '../components/Text';
import { Ul, Li } from '../components/List';
import { EmptyLayout } from '../layouts/EmptyLayout';

const CFPSuccessPage = props => {
  return (
    <EmptyLayout>
      <Flex sx={{ mb: 80, justifyContent: 'center' }}>
        <iframe
          src="https://giphy.com/embed/l4FGAREbOSRLKMiNq"
          width="480"
          height="270"
          frameBorder="0"
          className="giphy-embed"
          allowFullScreen
        ></iframe>
      </Flex>
      <H2 sx={{ textAlign: 'center' }}>
        Congratulations! <br />
        We’ve received your submission 🎉
      </H2>
      <Box
        sx={{
          mt: 80,
          p: 40,
          border: '1px solid',
          borderColor: 'text',
          width: '100%',
        }}
      >
        <H3>What happens after you submit your proposal</H3>
        <P>
          Your proposals are peer reviewed by members from the IxDA community -
          our selection committee. We review on the basis of quality, speaker
          experience, how well it aligns with the conference themes and other
          presentations, keeping in mind the overall aim: diversity of point of
          views.
        </P>
        <P>Here's key dates for you to remember:</P>
        <Ul>
          <Li>
            Deadline for submitting the proposals for both talks and workshops:{' '}
            <strong sx={{ fontWeight: 700 }}>11 Sept</strong>
          </Li>
          <Li>
            Selection confirmation:{' '}
            <strong sx={{ fontWeight: 700 }}>23 Oct</strong>
          </Li>
          <Li>
            Full Program and Workshops announced:{' '}
            <strong sx={{ fontWeight: 700 }}>16 Nov</strong>
          </Li>
        </Ul>

        <H3>What’s in it for you?</H3>
        <P>
          As a speaker or facilitator, you will be in the position to shape the
          discourse of the Interaction Design community, as well as bragging
          rights for being among the first thought leaders selected to feature
          on the new IxDTV platform.
        </P>
        <P>
          For each talk, you receive 1 free ticket for the conference and an
          honorarium. For each workshop, you get 50% of the proceeds from your
          workshop ticket sales and 1 free ticket for the conference.
        </P>
        <P>
          You will also receive the technical assistance, instructions, support
          and recording material needed to ensure the highest possible
          production value for your segment.
        </P>

        <H3>Anonymity details</H3>
        <P>
          Inspired by guidelines effective in Canadian journalism and law, IxD21
          will grant anonymity for the following reasons:
        </P>
        <Ul>
          <Li>
            The information obtained was only possible under the guise of
            confidentiality.
          </Li>
          <Li>The source’s willingness to give a talk depends on anonymity.</Li>
          <Li>The information must be of public interest.</Li>
          <Li>
            The disclosure of identity may incur harm to the presenting party.
          </Li>
        </Ul>
      </Box>
    </EmptyLayout>
  );
};

export default CFPSuccessPage;
